import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { slider1 } from "./assets";
import Carousel from "react-material-ui-carousel";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

export const settings = (slideNo: number, centerMode: boolean = false) => {
  return {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    // initialSlide: 0,
    adaptiveHeight: false,
    centerMode: centerMode,
    slidesToShow: slideNo,
    slidesToScroll: slideNo,
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true,
          arrows: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      
    ]
  
  }
};
const settingsForTripType = (slideNo: number, centerMode: boolean = false) => {
  return {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    adaptiveHeight: false,
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    
  }
};
import Slider from "react-slick";
// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <Slider {...settingsForTripType(this.props.slideNo, this.props.centerMode)}
        nextArrow={<Box><KeyboardArrowRight /></Box>}
        prevArrow={<Box><KeyboardArrowLeft /></Box>}
        className={this.props.className}    
      >
        {this.props.children}
      </Slider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  carouselImage: {
    width: "100%",
  },
  carousel: {
    flex: 1,
    maxHeight: 400,
    overflow: "hidden",
  },
  indicatorIconWrap: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    transform: "translateY(-120%)",
  },
  indicatorIcon: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    overflow: "hidden",
    display: "flex",
  },
};
// Customizable Area End
