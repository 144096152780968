import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getAuthToken } from "../../../components/src/utils";
import changeTime from "moment";
import { ChangeEvent } from "react";
const navigation = require("react-navigation")

interface MenuItem {
    title: string;
    link?: string;
};

interface TableColumn {
    id: string;
    label: string;
    maxWidth: number;
};

export interface salesResponse {
    transaction_type: string;
    trip: string;
    country: string;
    customer_name: string;
    transaction_date: string;
    discount_amount: string;
    sales_amount: string;
    refund_amount: string;
};

interface SalesReport {
    sales: SalesData;
    transation_type: string[];
    customers: [number, string][];
    trip_titles: string[];
    countries_list: [number, string][];
}

interface SalesData {
    data: Sale[];
    page_options: PageOptions;
}

interface Sale {
    id: string;
    type: string;
    attributes: SaleAttributes;
}

interface SaleAttributes {
    id: number;
    status: string;
    transaction_type: string;
    transaction_date: string;
    sales_amount: string;
    discount_amount: string;
    refund_amount: string;
    trip: Trip;
    country: Country;
    customer: Customer;
}

interface Trip {
    id: number;
    title: string;
    description: string;
    country_id: number;
    city_id: number;
    area: string;
    payment_method: string;
    price_per_person: number;
    account_id: number;
    created_at: string;
    updated_at: string;
    currency_id: number;
    trip_type_id: number;
    status: string;
    cancellation_reason: string | null;
}

interface Country {
    id: number;
    alpha_code: string;
    name: string;
    created_at: string;
    updated_at: string;
}

interface Customer {
    id: number;
    name: string;
    email: string;
    mobile: string;
    country: CountryData;
    nationality: CountryData;
}

interface CountryData {
    data: {
        id: string;
        type: string;
        attributes: CountryAttributes;
    };
}

interface CountryAttributes {
    alpha_code: string;
    name: string;
    flag_emoji: string;
    country_code: string;
    phone_number_length: number[];
}

interface PageOptions {
    total_pages: string;
    per_page: string;
    current_page: string;
    total_items: string;
}

interface ErrorDetail {
    token: string;
}

interface ErrorResponse {
    errors: ErrorDetail[];
}
interface Pageoptions {
    total_pages: string;
    per_page: string;
    current_page: string;
    total_items: string;
}

interface EmptyDataResponse{
    message: string
}
// Customizable Area End

const configJSON = require("./config");
export interface Props {
    navigation: typeof navigation;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    isMobileScreen: boolean;
    bradCrumbs: Array<MenuItem>;
    tableHeaderName: Array<TableColumn>;
    salesData: Array<salesResponse>
    seletedDateRange: string;
    dateFrom: Date | null,
    dateTo: Date | null,
    transationType: Array<{ value: string, label: string }>;
    tripName: Array<{ value: string, label: string }>;
    countriesList: Array<{ value: string, label: string }>;
    customersName: Array<{ value: string, label: string }>;
    selectedOption: { value: string, label: string };
    ErrorResponse: string;
    selectOption: { filterName: string, value: string };
    pageOptions: Pageoptions;
    selectTransationType: string;
    selectedTripName: string ;
    selectedCountry: string;
    selectedCustomers: string;
    emptyData: string;
    salesLoader:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class SelesReportsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    salesReportApiCallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            isMobileScreen: false,
            bradCrumbs: [
                {
                    title: 'My Bookings',
                    link: '/MyBookings'
                },
                {
                    title: `Sales Report`,
                },
            ],

            tableHeaderName: [
                { id: "trip_name", label: "Trip Name", maxWidth: 200 },
                { id: "trip_type", label: "Trip Type", maxWidth: 150 },
                { id: "country", label: "Country", maxWidth: 150 },
                { id: "customer_name", label: "Customer Name", maxWidth: 200 },
                { id: "trip_date", label: "Trip Date", maxWidth: 100 },
                { id: "no_of_people", label: "No. OF People", maxWidth: 120 },
                { id: "amount", label: "Amount", maxWidth: 80 },
                { id: "", label: "", maxWidth: 80 }
            ],
            salesData: [],
            seletedDateRange: '',
            dateFrom: null,
            dateTo: null,
            transationType: [],
            tripName: [],
            countriesList: [],
            customersName: [],
            selectedOption: {
                value: "",
                label: ""
            },
            ErrorResponse: "",
            selectOption: { filterName: "", value: "" },
            pageOptions: {
                total_pages: "",
                per_page: "10",
                current_page: "1",
                total_items: "",
            },
            selectTransationType: "",
            selectedTripName: "",
            selectedCountry : "",
            selectedCustomers: "",
            emptyData: "You have no available bookings",
            salesLoader:true
        };

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.mobileView()
        this.getSalesReportApi()
        window.addEventListener('resize', this.mobileView)
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.responseSucessCell(apiRequestCallId, responseJson)
            }
            else if (responseJson && responseJson.errors) {
                this.responseFailureCell(apiRequestCallId, responseJson)
            }

        }
    };

    salesReportApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        const token = await getAuthToken()

        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
            "Authorization": `Bearer ${token}`,
        };
        let salesReportRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        salesReportRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        salesReportRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
            salesReportRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
        salesReportRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(salesReportRequestMessage.id, salesReportRequestMessage);
        return salesReportRequestMessage.messageId;
    };

    responseSucessCell = async (apiRequestCallId: string, responseJson: SalesReport & EmptyDataResponse) => {
        if (apiRequestCallId === this.salesReportApiCallId) {
            this.getSalesReportApiSucessCallBack(responseJson);
        }
    };

    responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
        if (apiRequestCallId === this.salesReportApiCallId) {
            this.getSalesReportApiFailureCallBack(responseJson);
        }
    };

    getSalesReportApi = async () => {
        const {selectedCustomers,selectedCountry,selectedTripName,selectTransationType} = this.state
        const fromData = this.state.dateFrom && changeTime(this.state.dateFrom).format('YYYY-MM-DD')
        const endData = this.state.dateTo && changeTime(this.state.dateTo).format('YYYY-MM-DD')
        this.salesReportApiCallId = await this.salesReportApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.salesReportEndPoint}?limit=10&page=${this.state.pageOptions.current_page}&from=${fromData}&to=${endData}&trip_title=${selectedTripName}&country_id=${selectedCountry}&customer_id=${selectedCustomers}&type=${selectTransationType}`,
        })
    };

    getSalesReportApiSucessCallBack = (response: SalesReport & EmptyDataResponse ) => {
        if(response.sales){
        const salesData = response.sales.data.map((sales: Sale) => ({
            transaction_type: sales.attributes.transaction_type,
            trip: sales.attributes.trip.title,
            country: sales.attributes.country.name,
            customer_name: sales.attributes.customer.name,
            transaction_date: changeTime(sales.attributes.transaction_date).format('MMM DD, YYYY'),
            discount_amount: sales.attributes.discount_amount,
            sales_amount: sales.attributes.sales_amount,
            refund_amount: sales.attributes.refund_amount,
        }));

        const transationType = response?.transation_type.map((title: string) => ({
            value: title,
            label: title,
        }));

        const tripName = response?.trip_titles.map((title: string) => ({
            value: title,
            label: title,
        }));

        const countriesList = response.countries_list.map((title: [number, string]) => (
            {
                value: title[0].toString(),
                label: title[1]
            }
        ));

        const customersName = response.customers.map((customer: [number, string]) => ({
            value: customer[0].toString(),
            label: customer[1],
        }));

        this.setState({ salesData: salesData, transationType, tripName, countriesList, customersName, pageOptions: response.sales.page_options, salesLoader: false})
    }else{
        this.setState({emptyData: response.message, salesLoader: false, salesData: [],})
    }
        this.setState({ salesLoader:false}) 
    };

    getSalesReportApiFailureCallBack = (response: ErrorResponse) => {
        this.setState({ ErrorResponse: response.errors[0].token , salesLoader: false})
    };

    mobileView = () => {
        const isMobileScreen = window.innerWidth <= 960;
        this.setState({ isMobileScreen });
    };

    handleDateRange = () => {
        const { dateFrom, dateTo } = this.state;
        const startDateFormatted = dateFrom
            ? new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }).format(dateFrom)
            : '';

        const endDateFormatted = dateTo
            ? new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }).format(dateTo)
            : '';
        const dateRange = `${startDateFormatted} - ${endDateFormatted}`;
        this.setState({ seletedDateRange: dateRange })
    };

    handleChangeDate = (name: string, value: Date | null) => {
        if (name === "dateFrom") {
            this.setState({ dateFrom: value,pageOptions: {
                total_pages: "",
                per_page: "10",
                current_page: "1",
                total_items: "",
            }, })
        } else if (name === "dateTo") {
            this.setState({ dateTo: value , pageOptions: {
                total_pages: "",
                per_page: "10",
                current_page: "1",
                total_items: "",
            },})
        }
    };

    handleClearDate = () => {
        this.setState({ dateFrom: null, dateTo: null, seletedDateRange: '' }, () => this.getSalesReportApi())
    };

    updateAnyTransation = () => {
        this.setState({  selectOption: { filterName: "", value: "" }, selectTransationType: "", pageOptions: {
            total_pages: "",
            per_page: "10",
            current_page: "1",
            total_items: "",
        }, }, () => this.getSalesReportApi())
    };

    updateAnyTripName = () =>{
        this.setState({  selectedTripName: "",  pageOptions: {
            total_pages: "",
            per_page: "10",
            current_page: "1",
            total_items: "",
        }, }, () => this.getSalesReportApi())
    };

    updateAnyCountry = () =>{
        this.setState({  selectedCountry: "", pageOptions: {
            total_pages: "",
            per_page: "10",
            current_page: "1",
            total_items: "",
        }, }, () => this.getSalesReportApi())
    };

    updateAnyCustomers = () =>{
        this.setState({ selectedCustomers: "" , pageOptions: {
            total_pages: "",
            per_page: "10",
            current_page: "1",
            total_items: "",
        }, }, () => this.getSalesReportApi())
    };

    handleFilterChange = (filterName: string, value: string) => {
        if ((filterName == "selectedTransation")) {
            value == "Any" ? this.updateAnyTransation() :
                this.setState({
                    selectOption: { filterName: filterName, value: value }, selectTransationType: value, pageOptions: {
                        total_pages: "",
                        per_page: "10",
                        current_page: "1",
                        total_items: "",
                    },
                }, () => this.getSalesReportApi())
        } else if ((filterName == "selectedTripName")) {
            value == "Any" ? this.updateAnyTripName() :
                this.setState({
                    selectedTripName: value, pageOptions: {
                        total_pages: "",
                        per_page: "10",
                        current_page: "1",
                        total_items: "",
                    },
                }, () => this.getSalesReportApi())
        } else if ((filterName == "selectedCountry")) {
            value == 'Any' ? this.updateAnyCountry() :
                this.setState({
                    selectedCountry: value, pageOptions: {
                        total_pages: "",
                        per_page: "10",
                        current_page: "1",
                        total_items: "",
                    },
                }, () => this.getSalesReportApi())
        } else if ((filterName == "selectedCustomers")) {
            value == 'Any' ? this.updateAnyCustomers() :
                this.setState({
                    selectedCustomers: value, pageOptions: {
                        total_pages: "",
                        per_page: "10",
                        current_page: "1",
                        total_items: "",
                    }
                }, () => this.getSalesReportApi())
        } 
    };

    handlePagination = (event: ChangeEvent<unknown>, value: number) => {
        this.setState((prevState) => ({
            pageOptions: {
                ...prevState.pageOptions,
                current_page: String(value),
            },
        }), () => this.getSalesReportApi());
    };

    // Customizable Area End
}