// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { Box } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, Redirect,useNavigate } from 'react-router-dom';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import { Toaster } from 'react-hot-toast'
import Navbar from "../../components/src/Navbar";
import Footer from "../../components/src/Footer";
import Loader from "../../components/src/Loader.web";
import { getStorageData } from "../../framework/src/Utilities";
import Sidebar from "../../components/src/Sidebar";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails.web";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import {AdvancedSearchWeb} from "../../blocks/advancedsearch/src/AdvancedSearch.web";
import { OTPInputAuthWeb } from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Customform from "../../blocks/customform/src/Customform";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import { ForgotPasswordWeb } from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import { NewPasswordWeb } from "../../blocks/forgot-password/src/NewPassword.web";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import { UserProfileCustomerWeb } from "../../blocks/user-profile-basic/src/UserProfileCustomer.web";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import { DashboardWeb } from "../../blocks/dashboard/src/Dashboard.web";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import { LandingPageWeb } from "../../blocks/landingpage/src/LandingPage.web";
import { SummaryCardWeb } from "../../blocks/SummaryCard/src/SummaryCard.web";
import TravelAgencyRegistration from "../../blocks/email-account-registration/src/TravelAgencyRegistration.web"
import ReviewPageSignIn from "../../blocks/email-account-login/src/ReviewPageSignIn.web"
import { CreateTripWeb } from "../../blocks/ProductDescription/src/CreateTrip.web"
import { TripDetailsWeb } from "../../blocks/ordermanagement/src/TripDetails.web"
import { SingleTripWeb } from "../../blocks/ProductDescription/src/TripDetails.web"
import PaymentMethod from "../../blocks/ordermanagement/src/PaymentMethods.web"
import { BookingSummaryWeb } from "../../blocks/ordermanagement/src/BookingSummary.web"
import { BookingDetailsWeb } from "../../blocks/ordermanagement/src/BookingDetails.web"
import { MyBookingsWeb } from "../../blocks/ordermanagement/src/MyBookings.web"
import { BookingsListWeb } from "../../blocks/ordermanagement/src/BookingsList.web"
import { TripListWeb } from "../../blocks/catalogue/src/TripList.web";
import { TripListCalendarViewWeb } from "../../blocks/scheduling/src/TripListCalendarView.web"
import { ServiceProviderReviewsWeb } from "../../blocks/reviews/src/ServiceProviderReviews.web"
import { ChangePasswordWeb } from "../../blocks/Settings5/src/ChangePassword.web"
import { ContactusModalWeb } from "../../blocks/contactus/src/ContactusModal.web"
import { PersonalizeExpWeb } from "../../blocks/customform/src/PersonalizeExp.web"
import { PlanItineraryWeb } from "../../blocks/customform/src/PlanItinerary.web"
import { MakePaymentWeb } from "../../blocks/AdminConsole/src/MakePayment.web"
import PaymentStatus from "../../blocks/ordermanagement/src/PaymentStatus.web"
import AuthProvider from "../../blocks/user-profile-basic/src/AuthProvider.web"
import ShoppingCartWeb from "../../blocks/shoppingcart/src/ShoppingCart.web"
import {ChatWeb} from "../../blocks/ContentManagement/src/Chat.web"
import { ChatCustomerWeb } from "../../blocks/ContentManagement/src/ChatCustomer.web"
import { BookingDetailsCustomerWeb } from "../../blocks/ordermanagement/src/BookingDetailsCustomer.web"
import { getAuthToken } from "../../components/src/utils";
import { MyProfileWeb } from "../../blocks/Settings5/src/MyProfile.web"
import { SubscriptionPlanWeb } from "../../blocks/Settings5/src/SubscriptionPlan.web"
import { SubscriptionDetailFormWeb } from "../../blocks/Settings5/src/SubscriptionDetailForm.web"
import { ServiceProviderListWeb } from "../../blocks/advancedsearch/src/ServiceProviderList.web"
import { ServiceProviderDetailsWeb } from "../../blocks/advancedsearch/src/ServiceProviderDetails.web"
import { BookingReportWeb } from "../../blocks/analytics/src/BookingReport.web"
import { TermsAndConditionsWeb } from "../../blocks/ContentManagement/src/TermsAndConditions.web"
import { PrivacyPolicyWeb } from "../../blocks/ContentManagement/src/PrivacyPolicy.web"
import { AboutUsWeb } from "../../blocks/ContentManagement/src/AboutUs.web"
import { TripBookingsListWeb } from "../../blocks/ordermanagement/src/TripBookingList.web"
import SalesReport from  "../../blocks/analytics/src/SalesReport.web";
import SubscriptionPaymentStatusWeb from "../../blocks/Settings5/src/SubscriptionPaymentStatus.web"
 


const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},

InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Customform:{
 component:Customform,
path:"/Customform"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
};

const protectedRouteMap = {
  Dashboard: {
    component: DashboardWeb,
    path: "/Dashboard",
    wrapRoute: 'protectedRoute'
  },
  CreateTrip: {
    component: CreateTripWeb,
    path: "/CreateTrip",
    wrapRoute: 'protectedRoute'
  },
  MyBookings: {
    component: BookingsListWeb,
    path: "/MyBookings",
    wrapRoute: 'protectedRoute'
  },
  BookingDetails: {
    component: BookingDetailsWeb,
    path: "/BookingDetails/:id",
    wrapRoute: 'protectedRoute'
  },
  EditTrip: {
    component: CreateTripWeb,
    path: "/EditTrip/:id",
    wrapRoute: 'protectedRoute'
  },
 
  SingleTripDetails: {
    component: SingleTripWeb,
    path: "/SingleTripDetails/:id",
    wrapRoute: 'protectedRoute'
  },
  Chat: {
    component: ChatWeb,
    path: "/Chats",
    wrapRoute: 'protectedRoute'
  },
  TripList: {
    component: TripListWeb,
    path: "/TripList",
    wrapRoute: 'protectedRoute'
  },
  MyProfile: {
    component: MyProfileWeb,
    path: "/MyProfileTrip",
    wrapRoute: 'protectedRoute'
  },
  SubscriptionPlan: {
    component: SubscriptionPlanWeb,
    path: "/SubscripitonPlan",
    wrapRoute: 'protectedRoute'
  },
  SubscriptionDetailForm: {
    component: SubscriptionDetailFormWeb,
    path: "/PaymentDetail",
    wrapRoute: 'protectedRoute'
  },
  SubscriptionPaymentStatus: {
    component: SubscriptionPaymentStatusWeb,
    path: "/SubscriptionPaymentStatus",
    wrapRoute: 'protectedRoute'
  },
  TripListCalendarView: {
    component: TripListCalendarViewWeb,
    path: "/TripListCalendarView",
    wrapRoute: 'protectedRoute'
  },
  ServiceProviderReviews: {
    component: ServiceProviderReviewsWeb,
    path: "/ServiceProviderReviews",
    wrapRoute: 'protectedRoute'
  },
  BookingReport: {
    component: BookingReportWeb,
    path: "/BookingReport",
    wrapRoute: 'protectedRoute'
  },
  SalesReport: {
    component: SalesReport,
    path: "/SalesReport",
    wrapRoute: 'protectedRoute'
  },
  TripBookingList: {
    component: TripBookingsListWeb,
    path: "/TripBookingList/:id",
    wrapRoute: 'protectedRoute'
  },
  TermsAndConditions:{
    component:TermsAndConditionsWeb,
   path:"/TermsAndConditions",
  wrapRoute: 'protectedRoute'
  },
   PrivacyPolicy:{
    component:PrivacyPolicyWeb,
   path:"/PrivacyPolicy",
  wrapRoute: 'protectedRoute'
  },
   AboutUs:{
    component:AboutUsWeb,
   path:"/AboutUs",
  wrapRoute: 'protectedRoute'
  },
}

const authRouteMap = {
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
    wrapRoute: 'authRoute'
  },
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: "/ForgotPassword",
    wrapRoute: 'authRoute'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    wrapRoute: 'authRoute'
  },
  NewPassword: {
    component: NewPasswordWeb,
    path: "/NewPassword",
    wrapRoute: 'authRoute'
  },
  OTPInputAuth: {
    component: OTPInputAuthWeb,
    path: "/OTPInputAuth",
    wrapRoute: 'authRoute'
  },
  TravelAgencyRegistration: {
    component: TravelAgencyRegistration,
    path: "/TravelAgencyRegistration",
    wrapRoute: 'authRoute'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration",
    wrapRoute: 'authRoute'
  },
  ReviewPageSignIn: {
    component: ReviewPageSignIn,
    path: '/ProfileNotApproved',
    wrapRoute: 'authRoute'
  },
  LandingPage: {
    component: LandingPageWeb,
    path: "/LandingPage",
    wrapRoute: 'authRoute'
  },
  MyInterest: {
    component: PersonalizeExpWeb,
    path: "/MyInterest",
    wrapRoute: 'authRoute'
  },
  PlanItinerary: {
    component: PlanItineraryWeb,
    path: "/PlanItinerary",
    wrapRoute: 'authRoute'
  },
  TripDetails: {
    component: TripDetailsWeb,
    path: '/TripDetails/:id',
    wrapRoute: 'authRoute'
  },
  TravelAgencyList: {
    component: ServiceProviderListWeb,
    path: '/TravelAgencyList',
    wrapRoute: 'authRoute'
  },
  TravelBuddyList: {
    component: ServiceProviderListWeb,
    path: '/TravelBuddyList',
    wrapRoute: 'authRoute'
  },
  ServiceProviderDetails: {
    component: ServiceProviderDetailsWeb,
    path: '/ServiceProviderDetails/:type/:id',
    wrapRoute: 'authRoute'
  },
  AdvancedSearch:{
   component:AdvancedSearchWeb,
   path:"/AdvancedSearch",
   wrapRoute: 'authRoute'
  },
  TermsAndConditions:{
    component:TermsAndConditionsWeb,
   path:"/TermsAndConditions",
  wrapRoute: 'authRoute'
  },
   PrivacyPolicy:{
    component:PrivacyPolicyWeb,
   path:"/PrivacyPolicy",
  wrapRoute: 'authRoute'
  },
   AboutUs:{
    component:AboutUsWeb,
   path:"/AboutUs",
  wrapRoute: 'authRoute'
  },
}

const protectedRouteforCustomer = {
  BookingSummary: {
    component: BookingSummaryWeb,
    path: '/BookingSummary/:trip_id/:trip_schedule_id/:noOfPeople',
    wrapRoute: 'protectedRouteForCustomer'
  },
  MyBookings: {
    component: MyBookingsWeb,
    path: '/MyBookings',
    wrapRoute: 'protectedRouteForCustomer'
  },
  PaymentMethod: {
    component: PaymentMethod,
    path: "/PaymentMethod",
    wrapRoute: 'protectedRouteForCustomer'
  },
  SummaryCard: {
    component: SummaryCardWeb,
    path: "/SummaryCard",
    wrapRoute: 'protectedRouteForCustomer'
  },
  LandingPage: {
    component: LandingPageWeb,
    path: "/LandingPage",
    wrapRoute: 'protectedRouteForCustomer'
  },
  BookingDetails: {
    component: BookingDetailsCustomerWeb,
    path: '/BookingDetails/:id',
    wrapRoute: 'protectedRouteForCustomer'
  },
  TripDetails: {
    component: TripDetailsWeb,
    path: '/TripDetails/:id',
    wrapRoute: 'protectedRouteForCustomer',
    exact:true
  },
  EditBooking : {
    component: TripDetailsWeb,
    path: '/TripDetails/:id/:bookingId',
    wrapRoute: 'protectedRouteForCustomer',
    exact: true,
  },
  UserProfileCustomer: {
    component: UserProfileCustomerWeb,
    path: '/MyProfile',
    wrapRoute: 'protectedRouteForCustomer'
  },
  MyInterest: {
    component: PersonalizeExpWeb,
    path: "/MyInterest",
    wrapRoute: 'protectedRouteForCustomer'
  },
  PlanItinerary: {
    component: PlanItineraryWeb,
    path: "/PlanItinerary",
    wrapRoute: 'protectedRouteForCustomer'
  },
  MakePayment: {
    component: MakePaymentWeb,
    path: "/MakePayment/:trip_id/:trip_schedule_id/:noOfPeople/:cardId?",
    wrapRoute: 'protectedRouteForCustomer'
  },
  PaymentStatus: {
    component: PaymentStatus,
    path: "/PaymentStatus",
    wrapRoute: 'protectedRouteForCustomer'
  },
  TravelAgencyList: {
    component: ServiceProviderListWeb,
    path: '/TravelAgencyList',
    wrapRoute: 'authRoute'
  },
  TravelBuddyList: {
    component: ServiceProviderListWeb,
    path: '/TravelBuddyList',
    wrapRoute: 'authRoute'
  },
  ServiceProviderDetails: {
    component: ServiceProviderDetailsWeb,
    path: '/ServiceProviderDetails/:type/:id',
    wrapRoute: 'authRoute'
  },
  AdvancedSearch:{
    component:AdvancedSearchWeb,
    path:"/AdvancedSearch",
    wrapRoute: 'authRoute'
   },
   ChatCustomer:{
    component: ChatCustomerWeb,
    path:"/ChatCustomer",
    wrapRoute: 'authRoute'
   },
   ShoppingCart:{
    component: ShoppingCartWeb,
    path:"/Cart",
    wrapRoute: 'authRoute'
   },
   TermsAndConditions:{
    component:TermsAndConditionsWeb,
   path:"/TermsAndConditions",
   wrapRoute: 'authRoute'
  },
   PrivacyPolicy:{
    component:PrivacyPolicyWeb,
   path:"/PrivacyPolicy",
   wrapRoute: 'authRoute'
  },
   AboutUs:{
    component:AboutUsWeb,
   path:"/AboutUs",
   wrapRoute: 'authRoute',
  },
  

}

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const ProtectedComponent = (subscriptionPlansGet,account_type, loggedin, isChangePasswordOpen, handleChangePassword, largerScreen, handleContactus) => {

  return (
    account_type === 'customer'
      ? <>
        <Route render={() => <Navbar account_type={account_type} isLoggedIn={true} handleChangePassword={handleChangePassword}/>} />
        <Switch>
          {WebRoutesGenerator({ routeMap: protectedRouteforCustomer })}
          <Route render={() => <Redirect to="/LandingPage" />} />
        </Switch>
        <Route render={() => <Footer handleContactus={handleContactus} />} />
      </>
      :
      <>
      <Box 
        component="main" 
        sx={{
          flexGrow: 1,
          display: 'flex',
          color: '#212426',
          fontSize: '16px',
          lineHeight: '24px',
          fontFamily: 'inter',
          backgroundColor: '#F6FBFB',
        }}
      >
        
        {subscriptionPlansGet === "true" && <Sidebar />}
        <Box sx={{ flex: 1, overflow: 'hidden' }}>
          <Navbar 
            account_type={account_type} 
            isLoggedIn={true} 
            handleChangePassword={handleChangePassword} 
          />
          <Box sx={{
            overflow: 'auto',
            height: '100%',
            padding: largerScreen > 1279 ? '0 24px 24px 0' : '0'
          }}>
            <Switch>
              {WebRoutesGenerator({ routeMap: protectedRouteMap })}
              <Route render={() => <Redirect to="/Dashboard" />} />
            </Switch>
          </Box>
        </Box>
      </Box>
      <Route render={() => <Footer isLoggedIn={true} handleContactus={handleContactus} />} />
    </>
    
  )
};

const AppLayoutComponents = ( authRouteMap, handleContactus ) => {
  return (
    <>
      <Route render={() => <Navbar />} />
      <Switch>
        {WebRoutesGenerator({ routeMap: authRouteMap })}
        <Route render={() => <Redirect to="/LandingPage" />} />
      </Switch>
      <Route render={() => <Footer handleContactus={handleContactus} />} />
    </>
  )
};
class App extends Component {
  isLoaded = false;
  state = {
    loggedin: false,
    loading: true,
    account_type: '',
    isChangePasswordOpen: false,
    isContactusOpen: false,
    windowWidth: window.innerWidth
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };


  handleChangePassword = (open) => {
    this.setState({isChangePasswordOpen: open})
  }

  handleContactus = (open) => {
    this.setState({isContactusOpen: open})
  }

  async componentDidMount() {
    window.history.scrollRestoration = 'manual';
    const result = await getAuthToken()
    const account_type = await getStorageData('account_type')
    this.setState({
      loggedin: typeof result == 'string' ? true : false,
      account_type: account_type,
      loading: false
    });
    document.addEventListener('isLoggedIn', ({ detail }) => {
      this.setState({
        loggedin: detail.loggedin,
        account_type: detail.account_type
      })
    })
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    if (this.state.loading) {
      return <Loader loading={this.state.loading} />
    }
    const subscripitonPlansget=localStorage.getItem("subscriptionPlan")
    return (
      <AuthProvider>
      <View style={{ width: '100%', overflow: 'hidden auto' }}>
        <Toaster/>
        <Router>
          <React.Suspense fallback={<Loader loading={true} />}>
            <Switch>                         
              <Route
                render={() => (
                  this.state.loggedin
                    ? ProtectedComponent(subscripitonPlansget,this.state.account_type, this.state.loggedin, this.state.isChangePasswordOpen, this.handleChangePassword, this.state.windowWidth, this.handleContactus)
                    : AppLayoutComponents(authRouteMap, this.handleContactus)
                )}
              />
                 {WebRoutesGenerator({ routeMap })}

            </Switch>
            <ChangePasswordWeb key={`${this.state.isChangePasswordOpen} ${new Date()}`}
              open={this.state.isChangePasswordOpen}
              handlePasswordModalAppearance={this.handleChangePassword}
            />
            <ContactusModalWeb key={`${this.state.isContactusOpen} ${new Date()}`}
              open={this.state.isContactusOpen}
              handleContactusModalAppearance={this.handleContactus}
            />
          </React.Suspense>
        </Router>
        <ModalContainer />
      </View>
      </AuthProvider>
    );
  }
}

export default App;
