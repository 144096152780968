import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    styled,
    Divider
} from "@material-ui/core";
import { Pagination } from '@material-ui/lab';
import BookingDateSelect from "../../../components/src/BookingDateSelect.web";
import { Theme, ThemeProvider, createTheme } from "@material-ui/core/styles";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import SelesReportsController, { Props, salesResponse } from "./SalesReportController.web";
import FilterSelect from "../../../components/src/FilterSelect";
import { BookingReportTable } from "../../../components/src/BookingReportTable.web";
import { getRangePage } from "../../../components/src/utils";
import Loader from "../../../components/src/Loader.web";
const configJSON = require("./config");

// Customizable Area End

export default class SelesReports extends SelesReportsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    filterHeader = () => {
        return (
            <Grid container spacing={2} style={{ marginBottom: this.state.isMobileScreen ? '10px' : '10px' }}>
                <Grid item xs={12} sm={6} lg={3}>
                    <BookingDateSelect
                        fieldName="Date"
                        fieldValue={this.state.seletedDateRange}
                        onChange={this.handleChangeDate}
                        renderDateRange={this.handleDateRange}
                        handleApply={this.getSalesReportApi}
                        clearDate={this.handleClearDate}
                        startDate={this.state.dateFrom}
                        endDate={this.state.dateTo}
                        data-testId = "selectdataTestId"
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FilterSelect
                        data-testId="transactions"
                        field="Transation Type"
                        options={this.state.transationType}
                        filterName="selectedTransation"
                        handleFilterChange={this.handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FilterSelect
                        data-testId="tripNameTestId"
                        field="Trip"
                        options={this.state.tripName}
                        filterName="selectedTripName"
                        handleFilterChange={this.handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FilterSelect
                        data-testId="countryTestId"
                        field="Country"
                        options={this.state.countriesList}
                        filterName="selectedCountry"
                        handleFilterChange={this.handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FilterSelect
                        data-testId="customersTestId"
                        field="Customers"
                        options={this.state.customersName}
                        filterName="selectedCustomers"
                        handleFilterChange={this.handleFilterChange}
                    />
                </Grid>
            </Grid>
        );
    };

    responsiveCardTable = () => {
        return (
            this.state.salesData.map((salesData: salesResponse) => {
                return (
                    <CardContainer >
                        <CardContent style={{ padding: theme.spacing(2) }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography className="tripTitleName" gutterBottom>{salesData.trip}</Typography>
                                    <Typography className="tripTitleName"
                                        gutterBottom>
                                        <span className="salesTextName" >
                                            Country:</span>
                                        {salesData.country}</Typography>
                                    <Typography className="tripTitleName" gutterBottom><span className="salesTextName">Transaction Type:</span> {salesData.transaction_type}</Typography>
                                    <Typography className="tripTitleName" gutterBottom>
                                        <span className="salesTextName"
                                        >Customer Name:</span> {salesData.customer_name}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography className="tripTitleName" gutterBottom><span className="salesTextName">Transaction Date:</span> {salesData.transaction_date}</Typography>
                                    <Typography className="tripTitleName" gutterBottom><span className="salesTextName">Sales Amount:</span> {salesData.sales_amount}</Typography>
                                    <Typography className="tripTitleName" gutterBottom><span className="salesTextName">Discount Amount:</span> ${salesData.discount_amount}</Typography>
                                    <Typography className="tripTitleName" gutterBottom><span className="salesTextName">Refund Amount:</span> ${salesData.refund_amount}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContainer>
                )
            }
            )
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isMobileScreen,salesLoader,emptyData } = this.state
        return (
            <ThemeProvider theme={theme}>
                <Box style={{
                    marginLeft: isMobileScreen ? '20px' : 'none',
                    position: 'absolute',
                    top: isMobileScreen ? '115px' : '43px',
                }}>
                    <Breadcrumbs list={this.state.bradCrumbs} />
                </Box>
                <Box marginLeft={'20px'} display={'flex'} justifyContent={'space-between'} marginBottom={'32px'}>
                    <Typography style={{
                        fontWeight: 600,
                        fontSize: isMobileScreen ? '24px' : '32px',
                        lineHeight: 'normal',
                        letterSpacing: '0.1px',
                    }}>
                        {configJSON.salesTitle}
                    </Typography>

                </Box>
                {isMobileScreen && (<Box style={{ padding: '20px' }}>
                    {this.filterHeader()}
                </Box>)
                }
                <div style={{
                    backgroundColor: '#FFF',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08)',
                    borderRadius: '16px',
                    marginBottom: '16vh'
                }}>
                    <Box padding="33px">
                        {
                            !isMobileScreen && this.filterHeader()
                        }
                        {
                            !isMobileScreen &&
                            <BookingReportTable
                            salesLoader={salesLoader}
                            emptyData= {emptyData}
                                orders={this.state.salesData}
                                type="salesReport" />
                        }
                        {
                            isMobileScreen && this.state.salesData?.length > 0 &&
                            this.responsiveCardTable()
                        }

                    </Box>
                    {
                        Number(this.state.pageOptions.total_pages) > 1 &&
                        <>
                            <Divider />
                            <Box position={'relative'}>
                                <Box display={'flex'} padding={'32px'}>
                                    <StyledPagination data-testid="paginationBtn"
                                        count={Number(this.state.pageOptions?.total_pages)}
                                        page={Number(this.state.pageOptions.current_page)}
                                        data-testId = "paginationTestId"
                                        onChange={this.handlePagination}
                                    />
                                </Box>
                                <PaginationItems >
                                    {getRangePage(Number(this.state.pageOptions?.current_page), Number(this.state.pageOptions?.per_page), Number(this.state.pageOptions?.total_items))} of {Number(this.state.pageOptions?.total_items)} items
                                </PaginationItems>
                            </Box>
                        </>
                    }
                </div>
            </ThemeProvider >
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        },
        MuiButtonBase: {
            root: {
                '&.MuiPaginationItem-root': {
                    color: 'rgba(33, 36, 38, 0.50)',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',
                    display: 'flex',
                    width: '39.206px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&.Mui-selected': {
                        color: '#FE7F2D',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }
                }
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

const StyledPagination = styled(Pagination)({
    margin: 'auto',
    '& li:first-child': {
        borderRadius: '8px',
        border: '1px solid rgba(33, 36, 38, 0.20)',
        padding: '4px 4.9px',
    },
    '& li:last-child': {
        borderRadius: '8px',
        border: '1px solid rgba(33, 36, 38, 0.20)',
        padding: '4px 4.9px',
    },
});

const PaginationItems = styled(Typography)({
    top: '39%',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Inter',
    paddingLeft: '32px',
    letterSpacing: '0.1px',
    position: 'absolute',
    color: 'rgba(33, 36, 38, 0.80)',
    '@media (max-width:600px)': {
        top: '80%',
    },
});

const CardContainer = styled(Card)({
    marginBottom: theme.spacing(2),
    border: '1px solid #C3CAD9',
    borderRadius: '10px',
    "& .tripTitleName": {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '150%',
    },
    "& .salesTextName": {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '150%',
        color: '#9D9D9D',
        marginBottom: '8px'
    }
});

// Customizable Area End