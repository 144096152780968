import React from 'react'
import { successImg } from "../../blocks/email-account-registration/src/assets"
import { Typography, Button, Box, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { primaryColor } from "./theme"
import LazyImage from './LazyImage';
import { alertIcon } from '../../blocks/AdminConsole/src/assets';
import { setStorageData } from '../../framework/src/Utilities';
import i18next from 'i18next';




const useStyle = makeStyles((theme) => ({
    label: {
        color: 'rgba(33, 36, 38, 0.80)',
        lineHeight: '130%',
        width: '195px'
    },
    value: {
        fontWeight: 500,
        lineHeight: '130%',
    },
    tripImage: {
        width: '190px',
        height: '190px'
    },
    title: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '130%',
        opacity: 0.9
    },
    description: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '160%',
        opacity: 0.8,
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '800px',
        margin: '48px auto'
    },
    cardWrapper: {
        width:"60%",
        display: 'flex',
        padding: '32px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '12px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: 'none',
        height: 'min-content'
    },
    fontSize19: {
        fontSize: '19px',
    },
}));

const cardDetails = (list: Record<string, any>, classes: any) => {
    return (
        <div>
            {Object.entries(list).map(([label, value], index) => (
                <Box key={index} display="grid" gridTemplateColumns="auto 1fr" marginBottom={'12px'} gridColumnGap={32}>
                    <Typography className={classes.label}>{label}</Typography>
                    <Typography
                        className={classes.value}
                        style={{ color: value === "Successful" ? "green" : "#212426" }}
                    >
                        {value}
                    </Typography>               
                     </Box>
            ))}
        </div>
    );
};

const SubscriptionPaymentSuccess = ({ navigation, responseJson }: any) => {
    const { paymentStatus = false } = responseJson ?? {};
    const headingContent = paymentStatus ?  i18next.t('travelAgency.subscripitonPlan.subscriptionSuccess') : responseJson?.responseJson?.errors ? responseJson?.responseJson?.errors : i18next.t('travelAgency.subscripitonPlan.paymentFailure');
    const paraContent = paymentStatus ? i18next.t('travelAgency.subscripitonPlan.subscriptionSuccessMessage')
    : responseJson?.responseJson?.errors ?  i18next.t('travelAgency.subscripitonPlan.alreadySubscribed') : i18next.t('travelAgency.subscripitonPlan.paymentFailureMessage')


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '59.6vh', width: '554px', margin: '48px auto' }}>
                <LazyImage src={paymentStatus ? successImg : alertIcon} width={'164px'} height={'137px'} alt="success" />
                <Typography style={{ fontFamily: 'Inter', fontSize: '32px', color: 'rgba(33, 38, 35, 0.90)', fontWeight: 500 }}>{headingContent}</Typography>
                <Typography style={{ fontFamily: 'Inter', fontSize: '16px', color: 'rgba(33, 38, 35, 0.65)', fontWeight: 400, marginTop: '12px', textAlign: 'center' }}>{paraContent} </Typography>
                {paymentStatus ?
                    <Box style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                        <Button variant="outlined" data-testid="carddetailssave" style={{ textTransform: 'capitalize', padding: '10px 60px', borderRadius: '20px', color: '#fff', background: '#FE7F2D', border: "1px solid rgb(254, 127, 45)" }} onClick={() => {
                            setStorageData("subscriptionPlan", true);
                            navigation?.navigate("Dashboard");
                            window.location.reload()
                        }} >{i18next.t('travelAgency.subscripitonPlan.goToDashboard')}</Button>
                    </Box>
                    : responseJson?.responseJson?.errors ?
                    <Button variant="outlined" data-testid="carddetailssave" style={{ marginTop:"20px",textTransform: 'capitalize', padding: '10px 60px', borderRadius: '20px', color: '#fff', background: '#FE7F2D', border: "1px solid rgb(254, 127, 45)" }} onClick={() => {
                        navigation?.navigate("Dashboard");
                    }} >{i18next.t('travelAgency.subscripitonPlan.goToDashboard')}</Button>
                    :
                    <Button
                        onClick={() => navigation?.goBack()}
                        style={{
                            marginTop: '32px',
                            height: '56px',
                            border: `1px solid ${primaryColor}`,
                            backgroundColor: primaryColor,
                            color: '#ffffff',
                            textTransform: 'initial',
                            borderRadius: '51px',
                            padding: '16px 48px',
                            fontSize: '16px',
                            fontFamily: "Inter",
                            fontWeight: 500
                        }}
                    >
                        {i18next.t('travelAgency.subscripitonPlan.tryAgain')}</Button>
                }
            </div>
           
        </>
    )
}

export default SubscriptionPaymentSuccess;