import React, {useState, useRef, useEffect} from "react";
import {
    Select,
    MenuItem,
    Typography,
    FormControl,
    useMediaQuery
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles'
import { Categories } from "../../blocks/ProductDescription/src/Interface.web";
import BookingDateFilter from "./BookingDateFilter.web";
import KeyboardDownIcon from '@material-ui/icons/KeyboardArrowDown';
import i18next from 'i18next';

interface OptionsI {
    value: string;
    label: string;
}

interface BookingFilterCustomProps {
    fieldName: string;
    fieldValue: string;
    onChange: (name: string, value: Date | null) => void;
    renderDateRange: () => void;
    handleApply: () => void;
    clearDate: () => void;
    startDate: Date | null;
    endDate: Date | null;
}





const BookingDateSelect: React.FC<BookingFilterCustomProps> = ({
    fieldName,
    fieldValue,
    onChange,
    renderDateRange,
    handleApply,
    clearDate,
    startDate,
    endDate
}) => {
    const isMobile = useMediaQuery('(max-width:1023px)');

    const mrLeft = '-25px'
    const customHeight = 'auto';
    const inputCW = isMobile && fieldValue ? "40px": '105px';

    const [isOpen, setIsOpen] = useState(false);
    const [dateSelected, setDateSelected] = useState(false);
   
    const dropdownRef = useRef<HTMLDivElement>(null);

    const useStyles = makeStyles((theme) => ({
        mainDiv: {
            border: '1px solid',
            borderColor: '#C3CAD9',
            borderRadius: '8px',
            padding: '12px 24px',
            height: '48px',
            // maxWidth: width,
            minWidth: '133px',
            width: '100%',
            position: 'relative',
        },
        optionText: {
            color: '#212426',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
            padding: '8px 16px',
            whiteSpace: 'break-spaces',
            cursor: 'pointer',
            marginBottom: 0
        },
        placeholderText: {
            color: '#92929D',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.1px',
        },
        valueText: {
            color: '#212426',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.1px',
        },
        [theme.breakpoints.down('sm')]: {
            mainDiv: {
                padding: '12px 8px',
            },
            valueText: {
                fontSize: '14px'
            },
            placeholderText: {
                fontSize: '14px'
            }
        }
    
    }));
    const classes = useStyles();


    const handleClickOutside = (event: MouseEvent) => {
        if (!dateSelected && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleApplyClick = () => {
        handleApply();
        setIsOpen(false); // Close the modal when apply button is clicked
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dateSelected]); // Update event listener when dateSelected changes

    const direction = document.documentElement.dir


    return (
        <div
           className={classes.mainDiv}
            ref={dropdownRef}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 0,
                    padding: '12px 24px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span className={classes.placeholderText} style={{ fontFamily: 'Inter' }}>{i18next.t('travelAgency.myBookings.date')} :</span>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '106%',
                    left: 0,
                    width: '100%',
                    backgroundColor: '#ffffff',
                    border: '1px solid #C3CAD9',
                    borderRadius: '8px',
                    display: isOpen ? 'block' : 'none',
                    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
                    zIndex: 999,
                }}
            >
                <div style={{ height: customHeight, width: '100%', overflowY: 'scroll', paddingBottom: '15px' }}>
                <BookingDateFilter
                        onChange={(name, value) => {
                            onChange(name, value);
                            setDateSelected(true); // Set dateSelected to true when a date is selected
                        }}
                        renderDateRange={renderDateRange}
                        clearDate={()  => {
                            clearDate();
                            setDateSelected(false)
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        handleApplyClick={handleApplyClick}
                    />
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '2px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                {/* Replace the following div with your keyboard down icon */}
                <KeyboardDownIcon />
            </div>
            <input
                type="text"
                className={classes.valueText}
                value={fieldValue ? fieldValue : "Any"}
                readOnly
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    position: 'absolute',
                    top: 12,
                    left: direction === 'rtl'
                    ? (isMobile && fieldValue ? 35 : 50)
                    : (isMobile && fieldValue ? 70 : 100),
                    width: `calc(100% - ${inputCW})`,
                    textOverflow: 'ellipsis',
                    height: 'calc(100% - 24px)',
                    backgroundColor: '#ffffff',
                    border: 'none',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    marginLeft: mrLeft,
                    cursor: 'pointer',
                }}
            />
        </div>
    );
};


export default BookingDateSelect;
