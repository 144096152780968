import React from "react";

// Customizable Area Start
import {
    Typography, Box, Divider, Grid
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import { BookingReportTable } from "../../../components/src/BookingReportTable.web";
import { Pagination } from '@material-ui/lab';
import BookingDateSelect from "../../../components/src/BookingDateSelect.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import FilterSelect from "../../../components/src/FilterSelect";
import { getRangePage } from "../../../components/src/utils";
import ResponsiveBookingReportCard from "../../../components/src/ResponsiveBookingReportCard.web";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        },
        MuiButtonBase: {
            root: {
                '&.MuiPaginationItem-root': {
                    color: 'rgba(33, 36, 38, 0.50)',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',
                    display: 'flex',
                    width: '39.206px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&.Mui-selected': {
                        color: '#FE7F2D',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }
                }
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});


// Customizable Area End

import AnalyticsController, {
    Props
} from "./AnalyticsController.web";


export default class BookingReport extends AnalyticsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            bookingsData,
            bookingReportPagination,
            seletedDateRange,
            dateFrom,
            dateTo,
            bookingsTitle,
            countriesList,
            tripTypeList,
            isSmallScreen,
            reportLoader
        } = this.state;

        const filtersUI = () => {
            return (
              <Grid container spacing={2} style={{marginBottom: isSmallScreen ? '10px' : '32px'}}>
                <Grid item xs={12} sm={6} lg={3}>
                  <BookingDateSelect
                    fieldName="Date"
                    fieldValue={seletedDateRange}
                    onChange={this.handleDateChange}
                    renderDateRange={this.renderDateRange}
                    handleApply={this.getBookingsDataRequest}
                    clearDate={this.clearDate}
                    startDate={dateFrom}
                    endDate={dateTo}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FilterSelect
                    field="Trip"
                    options={bookingsTitle}
                    filterName="selectedTripName"
                    handleFilterChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FilterSelect
                    field="Trip type"
                    options={tripTypeList}
                    filterName="selectedTripType"
                    handleFilterChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FilterSelect
                    field="Country"
                    options={countriesList}
                    filterName="selectedCountry"
                    handleFilterChange={this.handleFilterChange}
                  />
                </Grid>
              </Grid>
            );
          };

        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={{
                    position: 'absolute',
                    top: isSmallScreen ? '115px' : '43px',
                    marginLeft: isSmallScreen ? '20px' : 'none',
                    
                }}>
                    <Breadcrumbs list={[
                        {
                            title: 'My Bookings',
                            link: '/MyBookings'
                        },
                        {
                            title: `Bookings Report`,
                        },
                    ]} />
                </Box>
                <Box marginLeft={'20px'} display={'flex'} justifyContent={'space-between'} marginBottom={'32px'}>
                    <Typography style={{
                        fontWeight: 600,
                        fontSize: isSmallScreen ? '24px' : '32px',
                        lineHeight: 'normal',
                        letterSpacing: '0.1px',
                    }}>
                        Booking Report
                    </Typography>

                </Box>
                {isSmallScreen && (<Box style={{padding: '20px'}}>{ filtersUI()}</Box>)}
                <div style={{
                    backgroundColor: '#FFF',
                    borderRadius: '16px',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08)',
                    marginBottom: '16vh'
                }}>
                    <Box padding={"33px"}>
                        {
                          !isSmallScreen &&  filtersUI()
                        }
                        {
                            !isSmallScreen && 
                            <BookingReportTable
                            reportLoader={reportLoader}
                                orders={bookingsData}
                                handleNavigation={this.navigateToTripBookingList} />
                               
                      }
                        {
                                isSmallScreen && bookingsData?.length > 0 &&
                                bookingsData.map((row: any) => (
                                    <ResponsiveBookingReportCard
                                    tripName={row.trip_name}
                                    tripType={row.tirp_type}
                                    country={row.country}
                                    customerName={row.customer_name}
                                    tripDate={row.date}
                                    numOfPeople={row.no_of_people}
                                    amount={row.amount}
                                    tripId={row.navigation_id}
                                    handleNavigation={this.navigateToTripBookingList}
                            />
                                ))
                            }

                    </Box>
                    {
                        bookingReportPagination?.total_pages > 1 && <> <Divider />
                            <Box position={'relative'}>
                                <Box display={'flex'} padding={'32px'}>
                                    <Pagination data-testid="paginationBtn" count={bookingReportPagination?.total_pages} page={this.state.page}
                                        onChange={this.handlePaginationChangeFBR}
                                        classes={{
                                            root: classes.paginationRoot
                                        }}
                                    />
                                </Box>
                                <Typography className={classes.paginationItems}>
                                    {getRangePage(bookingReportPagination?.current_page, bookingReportPagination?.per_page, bookingReportPagination?.total_items)} of {bookingReportPagination?.total_items} items
                                </Typography>
                            </Box>
                        </>
                    }

                </div>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = (theme: Theme) => ({
    flexContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '24px',
    },
    inputBlack: {
        color: "#212426E5",
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 500
    },
    tripLabel: {
        height: '100%',
        color: '#92929D',
        lineHeight: 'normal',
        letterSpacing: '0.1px',
        marginRight: '12px',
        whiteSpace: 'nowrap' as const,
        display: 'flex',
        alignItems: 'center'
    },
    selectElement: {
        padding: '0 12px 0 0',
        cursor: 'pointer',
        width: '100%',
        userSelect: 'none' as const,
        borderRadius: 0,
        marginRight: '24px',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        textOverflow: 'ellipsis',
        height: '100%',
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.1px',
        display: 'flex',
        alignItems: 'center'
    },
    searchInput: {
        '&::placeholder': {
            color: '#92929D',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        }
    },
    input: {
        color: 'rgba(33, 36, 38, 0.90);',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.1px',
    },
    paginationItems: {
        top: '39%',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        paddingLeft: '32px',
        letterSpacing: '0.1px',
        position: 'absolute' as const,
        color: 'rgba(33, 36, 38, 0.80)',
        [theme.breakpoints.down('sm')]:{
            top: '80%'
        }
    },
    paginationRoot: {
        margin: 'auto',
        '& li:first-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& li:last-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
    },
    menuRoot: {
        display: 'flex',
        padding: '8px 0px',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        gap: '4px',
        alignSelf: 'stretch',
    },
    tableBody: {
        '& tr:first-child td': {
            paddingTop: '19px'
        }
    },
    noBookingText: {
        color: 'rgba(33, 36, 38, 0.90)',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
    },
    fitler_date: {
        maxWidth: "180px"
    },
    dropdownContainer: {
        display: 'flex',
        gridColumnGap: '15px',
    },
})

const BookingReportWeb = withStyles(styles)(BookingReport)
export { BookingReportWeb }
// Customizable Area End
