import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDownIcon from '@material-ui/icons/KeyboardArrowDown';
import i18next from 'i18next';
interface FilterSelectProps {
    field: string;
    options: Array<{ value: string; label: string }>;
    filterName: string;
    advancedSearch?: boolean;
    handleFilterChange: (filterName: string, value: string) => void;
    selectedLanguage?:String
}

interface FieldWidths {
    [key: string]: string;
}

const fieldWidths: FieldWidths = {
    countryId: '100%',
    tripType: '100%',
    sortBy: '100%',
    tripTitle: '100px',
    selectedTripName: '215px',
    selectedTripType: '211px',
    selectedCountry: '234px',
    selectedTransation: "234px",
    selectedCustomers: "234px"
};

const marginLeft: FieldWidths = {
    countryId: '5px',
    tripType: '12px',
    sortBy: '-1px',
    selectedTripName: '6px',
    selectedTripType: '8px',
    selectedCountry: '2px',
    tripTitle: '0px',
    selectedTransation: "2px",
    selectedCustomers: "2px"
};

const inputWidth: FieldWidths = {
    countryId: '134px',
    tripType: '141px',
    sortBy: '128px',
    selectedTripName: '45px',
    selectedTripType: '86px',
    selectedCountry: '74px',
    selectedTransation: "144px",
    selectedCustomers: "144px",
    tripTitle: '40px',
};

const FilterSelect: React.FC<FilterSelectProps> = ({
    field,
    options,
    filterName,
    advancedSearch = false,
    handleFilterChange,
    selectedLanguage
}) => {
    const width = fieldWidths[filterName] || '267px';
    const mrLeft = marginLeft[filterName];
    const customHeight = filterName === 'countryId' ? '385px' : 'auto';
    const inputCW = inputWidth[filterName];
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string }>({
        value: advancedSearch ? 'highToLow' : filterName === 'sortBy' ? 'AtoZ' : i18next.t('travelAgency.myTrips.any'),
        label: advancedSearch ? (selectedLanguage === "arabic" ? "التقييم (مرتفع إلى منخفض)" : "Rating (high to low)") : filterName === 'sortBy' ? (selectedLanguage === 'arabic' ? "الحروف الأبجدية (أ إلى ي)" : 'Alphabet (A to Z)') : i18next.t('travelAgency.myTrips.any'),
    });

    const dropdownRef = useRef<HTMLDivElement>(null);

    const useStyles = makeStyles((theme) => ({
        mainDiv: {
            border: '1px solid',
            borderColor: '#C3CAD9',
            borderRadius: '8px',
            padding: '12px 24px',
            height: '48px',
            minWidth: '133px',
            width: '100%',
            position: 'relative',
        },
        optionText: {
            color: '#212426',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
            padding: '8px 16px',
            whiteSpace: 'break-spaces',
            cursor: 'pointer',
            marginBottom: 0,
        },
        placeholderText: {
            color: '#92929D',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.1px',
        },
        valueText: {
            color: '#212426',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.1px',
        },
    }));
    const classes = useStyles();

    const handleOptionClick = (option: { value: string; label: string }) => {
        setSelectedOption(option);
        setIsOpen(false);
        handleFilterChange(filterName, option.value);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={classes.mainDiv} ref={dropdownRef} style={{ minWidth: width }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: "-10px",
                    padding: '12px 24px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span className={classes.placeholderText}>{field} :</span>
                <input
                    type="text"
                    className={classes.valueText}
                    value={selectedOption.label}
                    readOnly
                    onClick={() => setIsOpen(!isOpen)}
                    style={{
                        width: `calc(100% - ${inputCW})`,
                        textOverflow: 'ellipsis',
                        height: 'calc(100% - 0px)',
                        backgroundColor: '#ffffff',
                        border: 'none',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        marginLeft: mrLeft,
                        cursor: 'pointer',
                    }}
                />
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '106%',
                    left: 0,
                    width: '100%',
                    backgroundColor: '#ffffff',
                    border: '1px solid #C3CAD9',
                    borderRadius: '8px',
                    display: isOpen ? 'block' : 'none',
                    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
                    zIndex: 999,
                }}
            >
                {filterName !== 'sortBy' && (
                    <p
                        onClick={() => handleOptionClick({ label: 'Any', value: 'Any' })}
                        className={classes.optionText}
                        style={{ borderBottom: '1px solid #C3CAD9' }}
                    >
                        {i18next.t('travelAgency.myTrips.any')}
                    </p>
                )}
                <div style={{ height: customHeight, width: '100%', overflowY: 'scroll', paddingBottom: '15px' }}>
                    {options.map((item, index) => (
                        <p
                            key={index}
                            onClick={() => handleOptionClick(item)}
                            className={classes.optionText}
                        >
                            {item.label}
                        </p>
                    ))}
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                
                <KeyboardDownIcon />
            </div>
        </div>
    );
};

export default FilterSelect;
